@mixin course-detail-theme($theme) {

	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$accent: map-get($theme, accent);
	$primary: map-get($theme, primary);

	#course-detail-section {

		.header, .report {
			background: map-get($primary,400); //if the next style fails
			background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

			.title,.logo {
				color: #ffffff;
			}
		}
		
		.section {
			border-bottom-color: map-get($foreground, divider);

			.comments {

				.comment {

					.comment-bubble {
						background-color: map-get($background, app-bar);
						border-color: map-get($background, app-bar) !important;

						&:after,
						&:before {
							border-color: map-get($background, app-bar) transparent transparent transparent;
						}
					}
				}				
			}
		}

		.rating {
			justify-content: left !important;
			.star-container {
				margin-left: 0px !important;
			}
		}
		
		.average-rate {
			.rating {
				float: left;
				.star-container {
					margin-right: 10px;
				}				
			}
		}

	}

}
