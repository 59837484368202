@mixin poll-result-theme($theme) {

	$accent: map-get($theme, accent);
	$primary: map-get($theme, primary);

	#poll-result {
        .header {
			background: map-get($primary,400); //if the next style fails
			background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

			.title,.logo {
				color: #ffffff;
			}
		}

		.result-overview-section {
            background-color: #FAFAFA;
            .title-text {
                color: #6e6e6e;
            }
        }

		.answers-section{
			background-color: #F5F5F5;
			.content-card{
				background: white;
			}
		}
    }
}