@mixin benefit-theme($theme) {
    
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #benefit {

        .header,.report{
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }
        }

        .delete-icon {
            color: map-get($accent, 500);
            cursor: pointer;
        }

        .icon-picker {
            .icon-search {
                padding-top: 10px;
                input {
                    padding: 0 4px;
                }
            }
            .icon-search:before {
                content: none;
            }
        }

        .ctm-editor {
            width: 100%;
            i {
                font-family: Montserrat, sans-serif !important;
                font-style: italic;
                font-size: inherit;
                color: inherit;
            }
        }         
    }
}