@mixin user-home-theme($theme) {
    
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #user-home {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .header-text {

                h1 {
                    color: white;
                }

                h2 {
                    color:  white;
                }
            }
        }

        .view-card{
            background: white;
        }

            
        // .market-section, .education-section {
        //     background: #fafafa;
        // }

        .company-section {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);
            .cia-title {
                color: white;
            }
        }


        .polls-section, .market-section {
            background: #fafafa;
        }

        .suggestions-section,.education-section {
            background: #F5F5F5;
        }

        .feedback-section, .benefits {
            background: url('/assets/images/backgrounds/benefits-edited.jpg') no-repeat;
            background-size: cover;
            background-position: center;
        }

        .pay {
            background-color: #FAFAFA;

            .pay-infos {

                .salary,.savings,.payroll {

                    .content,.content-front {
                        background: white;

                        .header {

                            background: white;

                            .fa-money-bill-alt, .fa-file-invoice-dollar, .payroll-icon {
                                color: map-get($primary, 500);
                            }
                            
                        }

                        .button {
                            color: white;
                        }
                    }
                }
            }
        }

        .benefits {
            background-color: #F5F5F5;

            .benefits-sum {
                color: map-get($primary, 500);
            }
        }


        .market {
            background: #FAFAFA;

            .market-infos {

                .flexitime,.savings,.payroll {

                    .content {
                        background: white;

                        .header {
                            background: map-get($primary,500);
                            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);                            
                               
                            .market-icon {
                                color: white;
                            }

                            .market-data {
                                color: white;
                            }
                          
                        }
                    }
                }
            }
        }

        .company {
            background-color: #F5F5F5;
        }

        .expenses {
            background: map-get($primary,500);
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title {
                color: white;
            }

            .expenses-infos {
                .expenses-chart {
                    .quantity {
                        color: map-get($primary,500);
                    }

                }

                .expenses-card {

                    .content {
                        background: white;

                        .header {

                            background: white;

                            .expenses-icon {
                                color: map-get($primary, 500);
                            }
                            
                        }
                        .second-button{
                            background-color: #DCDCDC;
                        }
                        .button {
                            color: white;
                        }
                    }
                }
            }
            
        }

        .education-section,.polls-section { 
            .shortInfo {
                color: map-get($primary,500);
                text-transform: uppercase;
            }            
        }
    }

}
