@mixin toolbar-theme($theme) {

    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    toolbar {

        .user-button {
            .avatar {
                border-color: map-get($accent, 500);
            }
            .user-menu-icon {
                color: map-get($accent, 500);
            }
        }

        .toolbar-separator {
            background: map-get($foreground, divider);
        }

        .help-link {
            color: map-get($accent, 500);
        }

        .notifications-button {
            background: map-get($primary ,500);
            background: linear-gradient(to right, map-get($primary,400), map-get($accent,500));
            .notif-icon{
                color: white;
            }

            .unreadCount-button{

                .badge {
                    font-size: 12px;
                    color: white;
                    background-color: #4285F4; 
                }
                
            } 
        }
    }
}