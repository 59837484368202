@mixin benefit-detail-theme($theme) {
    
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);  
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);

  #benefit-detail-section {

    .header, .report {
      background: map-get($primary,400); //if the next style fails
      background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

      .title,.logo {
        color: #ffffff;
      }
    }

		.section {
			border-bottom-color: map-get($foreground, divider);

			.comments {

				.comment {

					.comment-bubble {
						background-color: map-get($background, app-bar);
						border-color: map-get($background, app-bar) !important;

						&:after,
						&:before {
							border-color: map-get($background, app-bar) transparent transparent transparent;
						}
					}
				}				
			}
    }
    
		.rating {
			justify-content: left !important;
			.star-container {
				margin-left: 0px !important;
			}
    }  
    
    .average-rate {
      .rating {
        float: left;
        .star-container {
          margin-right: 10px;
        }				
      }
    }    

    .ctm-editor {
      .ck-editor__editable {
        background: transparent !important;
        border: none !important;
        padding: 0px;
      }
      .ck-toolbar_grouping {
        border: 1px solid #FAFAFA !important;
      }
      i {
        font-family: Montserrat, sans-serif !important;
        font-style: italic;
        font-size: inherit;
        color: inherit;
      }
    }  
    
  }

  .card-div {
    .content {
      .card-head {              
        .fa-money-bill-alt, .fa-file-invoice-dollar, .icon-color {
          color: map-get($primary, 500);
        }                
      }
    }
  }
    
  // month list toggle button
  .month-list-toggle {
    .mat-button-toggle-label-content {
      font-weight: 600;
      line-height: 34px;
      padding: 0 11px;
    }
    .mat-button-toggle-checked {
      background-color: map-get($accent, 500);
      .mat-button-toggle-label-content {
        color: #ffffff;
      }            
    }    
  }
  
  .ctm-divider {
    border: 1px solid #d3d3d3 !important;
    height: 1px !important;
    background: #d3d3d3 !important;
    border-radius: 3px !important;
  } 

  // Config value slider
  .config-slider {
    width: 97%;
    height: 8px;
    pointer-events: none;
    .mat-slider-track-wrapper {
      height: 8px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;                
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;      
      left: -1px;             
    }
    .mat-slider-track-fill {
      height: 8px;
    }
    .mat-slider-track-background {
      height: 8px;                       
    }
    // Slider thumb show css
    .mat-slider-thumb-label {
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
      right: -16px;
      width: 34px;
      height: 34px;           
    }

    .mat-slider-thumb {
      transform: scale(0) !important;
    }

    .mat-slider-thumb-label-text {
      opacity: 1 !important;
    }
    .mat-slider-wrapper {
      height: 0px;
      position: relative;
    }      
  } 
  .provider-style {

    background: map-get($primary,400); //if the next style fails
    background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

    color: #ffffff;
  }

  // Configure contractor
  .config-contractor {
    .benefit-input {
      font-weight: 600;
      input {                
        border: 2px solid map-get($accent, 500);
        width: 60px;
        padding: 8px 0px 8px 4px;
        border-radius: 5px;        
        text-align: center;
        margin-right: 7px;
      }
    }

    .slider-w {
      max-width: 400px;
    }
    
    .benefit-dialog-slider {
      width: 100%;
      padding: 0px;
      height: 40px;
      .mat-slider-wrapper {
        left: 0px;
        right: 0px;
        top: 18px;
      }
      .mat-slider-track-wrapper {
        height: 8px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;                
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;                   
      }
      .mat-slider-track-fill {
        height: 8px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;                
      }
      .mat-slider-track-background {
        height: 8px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;     
        left: 4px;                           
      }
      .mat-slider-thumb {
        bottom: -14px;
        width: 23px;
        height: 23px;
        right: -17px;
      }
    }
    .error-msg {
      color: red;
      font-size: 12px;
      margin-top: 5px;
      font-weight: normal;
    }  
  }

}