@mixin benio-theme($theme) {
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    //Toggle buttons
    .mat-button-toggle-checked {
        background-color: map-get($accent, 400);
        .mat-button-toggle-label-content {
            color: #ffffff;
        }            
    }

    //Dialogs or modals, delete padding to get rid of the  title or toolbar's default padding 
    .mat-dialog-container {
        padding:0;
    }

    .benio-blue-heading {
        color: map-get($primary,500);
        font-weight: 600;
    }


    // .mat-progress-bar-buffer {
    //     background-color: lightgray;
    // }

    .benio-lightest-grey-section{
        background-color: #FAFAFA;
    }   
    
    .benio-light-grey-section{
        background-color: #F5F5F5;
    }  
    .benio-picture-background-section {
        background: url('/assets/images/backgrounds/benefits-edited.jpg') no-repeat;
        background-size: cover;
        background-position: center;
    }

    .benio-gradient-section{
        background: map-get($primary,400); //if the next style fails
        background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);
    } 

    .benio-section-content {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;  
        padding-top: 48px;
        padding-bottom: 48px;
        padding-left:  32px;
        padding-right: 32px;
    }
  
    .benio-icon-with-bg {
        background-color: map-get($primary,50);
        .primary-color-icon {
            color:map-get($primary,500);
        }
    }

    .benio-tabs {
        .mat-tab-header {
            background-color: map-get($primary, 500) !important;
            border: none;
        }              

        .mat-tab-label-container {
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding: 0px 32px;
        }

        // tab labels 
        .mat-tab-labels, .mat-tab-label, .mat-tab-link {
            background-color: map-get($primary, 500);
            color:white;
        }
        
        // active tab label
        .mat-tab-label-active {
            background-color: map-get($primary, 500);
            color: white;
            opacity: 1;
        }

        // ink bar
        .mat-ink-bar {
            background-color: white;
        }

        .form {
            background-color: white;
        }   
    }

    .benio-card {
        background-color: white;
        padding: 16px;
        border-radius: 8px 8px 8px 8px;    
        box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    }

    
}