@mixin sm-info-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-sm-info-card {
        .benio-sm-info-card {
            background: white;
        }
    }

}
