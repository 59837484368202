@mixin profile-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #profile {

        .header {
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);
            .title,.logo {
                color: white;
            }            
        }         

        .delete-icon {
            color: map-get($accent, 500);
            cursor: pointer;
        }        

    }

}


