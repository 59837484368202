@mixin admin-skills-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #admin-skills {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                    color: white;
                }
        }

        .summary-section,.all-feedback-section {
            background:#FAFAFA;
            .view-card{
                background: white;
            }
            .feedback-total{
                color: map-get($primary,500);
            }
        }

    }


}
