@mixin enps-poll-theme($theme) {
    
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);

  #enps-poll {

    .rate-slider-btn {
      max-width: 300px;
      input {                
        border: 2px solid map-get($accent, 500);
        width: 50px;
        padding: 4px 0px 4px 4px;
        border-radius: 5px;
        font-weight: 600;
        text-align: center;
        margin-right: 7px;
      }                
    }    

    .poll-slider {
      width: 100%;
      padding: 0px;
      height: 25px;
      .mat-slider-wrapper {
        top: 10px;
      }
      .mat-slider-track-wrapper {
        height: 8px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;                
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;                   
      }
      .mat-slider-track-fill {
        height: 8px;
      }
      .mat-slider-track-background {
        height: 8px;                           
      }
      .mat-slider-thumb {
        bottom: -14px;
        right: -12px;
        width: 23px;
        height: 23px;
      }
    }    

  }
  
}