@mixin lg-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-lg-card {
        .benio-lg-card {
            background: white;

            .card-header {

                .header-info,.mandatory {
                    color: white;
                } 
            }

            .title {
                .shortInfo {
                    color: map-get($primary,500);
                }
            }

            .certificate {
                .icon {
                    color: map-get($primary,500);
                }
            }
        
            
            .prices {
                .free {
                    color: map-get($primary,500);
                }                            
            }

            .mandatory {
                background: map-get($primary,500);
                padding: 4px;
            }            
            
        }
    }

}
