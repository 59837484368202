@mixin my-courses-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #my-courses {
       
        .header{
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);
            
            .title,.logo {
                color: white;
            }
        }

        .my-next-courses {
            background-color: #FAFAFA;         
        }

        .all-courses {
            background-color: #F5F5F5;
        }
    }

}
