@mixin admin-poll-results-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    #results {
        .polls-section {
            background: #FAFAFA;
            .poll-icon {
                background-color: map-get($primary,50);
                color:map-get($primary,500);
            }
        }
        .poll-charts-secion {
            background: #f5f5f5;
            .view-card{
                background: white;
            }

            .answered-evo{
                color: black;
            }
            .answered-percentage{
                color: map-get($accent,500);
            }
            .answered-total{
                color: map-get($primary,500);
            }
        }
        
       
    }
}