@mixin edit-poll-theme($theme) {

	$accent: map-get($theme, accent);
	$primary: map-get($theme, primary);

	#edit-poll {

		.header{
			background: map-get($primary,400); //if the next style fails
			background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

			.title,.logo {
				color: #ffffff;
			}
		}
		
		.poll-section {
			background: #FAFAFA ; 
			.font-color-primary {
				color: map-get($primary,500);
			}

			.icon-bg {
				background-color: map-get($primary,50);
				.title-icon {
					color:map-get($primary,500);
				}
			}
		}

	}

}
