@mixin course-request-dialog-theme($theme) {

    .course-request-dialog {
  
      @include media-breakpoint('xs') {
        width: 100%;
      }
  
      @include media-breakpoint('gt-xs') {
        width: 720px;
      }
  
      .mat-dialog-container {
        padding: 0 !important;
      }
  
      .dialog-content-wrapper {
        max-height: 85vh;
        display: flex;
        flex-direction: column;
      }
  
      .primary-color-input,
      .secondary-color-input {
        padding: 6px 8px;
      }
    }
  
  }  