@use '@angular/material' as mat;
@mixin my-payrolls-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #my-payrolls {

        .header,.savings{
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }
        }

        .payroll {
            background-color: #FAFAFA;
            .month {
                color: map-get($primary,500);
            }
        } 

    }

    // year tab and month section
    .year-tab-section {
        background-color: map-get($primary, 500) !important;
    }

    .year-label {
        text-align: center;
        font-weight: 600;
        padding-top: 12px;
        padding-bottom: 12px;
        color: #ffffff;
    }

    .month-list-section {
        border-bottom: 2px solid #dbdbdbba;
        padding-top: 12px;
        padding-bottom: 12px;    
    }

    .month-round-unselected, .month-round-selected {
        font-weight: 600;    
        cursor: pointer; 
        text-align: center;     
        padding-left: 5px;
        padding-right: 5px;
        .line {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin: auto;
            padding: 3px;
            transition: 0.3s;
            .round {
                width: 35px;
                height: 35px;
                text-align: center;
                line-height: 35px;
                border-radius: 50%;
                margin: auto;
                transition: 0.3s; 
            }            
        }
        .text {
            margin-top: 8px;
            transition: 0.3s;
            font-size: 13px;
        }      
    }

    .month-round-unselected {
        .line {
            border: 2px solid #f5f5f5;
            .round {
                background-color: #d1d3d4; 
                color: #ffffff;
            }            
        }
        .text {
            color: #656565;
        }    
    }

    .month-round-selected {
        .line {
            border: 2px solid map-get($accent, 500);
            .round {
                background-color: map-get($accent, 500);
                color: #ffffff;
            }            
        }
        .text {
            color: map-get($accent, 500);
        }
    }    

    .color-tabs {
        .mat-tab-header {
            background-color: map-get($primary, 500) !important;
            border-bottom: none !important;
            .mat-tab-label {
                color: map-get($primary, 200);            
                opacity: 1;
                height: 59px;
            }        
            .mat-tab-label-active {
                background-color: map-get($primary, 600);
                color: #ffffff;            
                opacity: 1;
            }
            .mat-ink-bar {
                background-color: #ffffff !important;
            }
            .mat-tab-header-pagination-disabled {
                .mat-tab-header-pagination-chevron {
                    border-color: rgba(255, 255, 255, 0.38) !important;
                }
                color: rgba(255, 255, 255, 0.38) !important;
            }
            .mat-tab-header-pagination-before, .mat-tab-header-pagination-after {
                .mat-tab-header-pagination-chevron {
                    border-color: rgba(255, 255, 255, 0.87);
                }
                color: rgba(255, 255, 255, 0.87);
            }         
        }
    }    

    // dialog css
    .benefit-dialog {
        padding: 10px;
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        .benefit-dialog-box {
            max-width: 365px;
            box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
            margin: auto;
            position: relative;
            top: 25%;
            padding: 25px 15px 15px 15px; 
            border-radius: 5px;     
            font-weight: 600;
            overflow: auto;
            .mat-checkbox-label {
                font-size: 14px;
            }
            .benefit-dialog-text {
                input {                
                    border: 2px solid map-get($accent, 500);
                    width: 60px;
                    padding: 4px 0px 4px 4px;
                    border-radius: 5px;
                    font-weight: 600;
                    text-align: center;
                    margin-right: 7px;                    
                }
            }
            .benefit-dialog-button {
                float: right;
                margin-top: 17px;           
            }
            .benefit-dialog-close {
                position: absolute;
                top: 7px;
                right: 15px;
                font-size: 18px;
                cursor: pointer;
            }            
            .benefit-dialog-slider {
                width: 100%;
                padding: 0px;
                height: 40px;
                .mat-slider-wrapper {
                    left: 0px;
                    right: 0px;
                    top: 18px;
                }
                .mat-slider-track-wrapper {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;                
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;                   
                }
                .mat-slider-track-fill {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;                
                }
                .mat-slider-track-background {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;     
                    left: 4px;                           
                }
                .mat-slider-thumb {
                    bottom: -14px;
                    width: 23px;
                    height: 23px;
                    right: -17px;
                }
            }
            .error-msg {
                color: red;
                font-size: 12px;
                margin-top: 5px;
                font-weight: normal;
            }
        }
    }

    // multiple dialog css
    .multiple-benefit-dialog {
        padding: 10px;
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        .benefit-dialog-box {
            max-width: 455px;
            box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
            margin: auto;
            position: relative;
            top: 12%;
            padding: 30px 24px 22px 24px; 
            border-radius: 5px;     
            font-weight: 600;
            overflow: auto;
            .hire-btn {
                cursor: pointer;
                border: none;
                background: none;
                color: map-get($accent, 600);
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;                
            }
            .mat-checkbox-label {
                font-size: 14px;
            }
            .benefit-dialog-button {
                float: right;           
            }
            .benefit-dialog-close {
                position: absolute;
                top: 5px;
                right: 15px;
                font-size: 18px;
                cursor: pointer;
            }             
            .b-slider-w {
                input {                
                    border: 2px solid map-get($accent, 500);
                    width: 60px;
                    padding: 4px 0px 4px 4px;
                    border-radius: 5px;
                    font-weight: 600;
                    text-align: center;
                    margin-right: 7px;
                }        
                .mat-stroked-button {
                    line-height: 32px;
                }
            }
            .benefit-dialog-slider {
                width: 100%;
                padding: 0px;
                height: 25px;
                .mat-slider-wrapper {
                    left: 0px;
                    right: 0px;
                    top: 10px;
                }
                .mat-slider-track-wrapper {
                    height: 8px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;                
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;                   
                }
                .mat-slider-track-fill {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;                
                }
                .mat-slider-track-background {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;     
                    left: 4px;                           
                }
                .mat-slider-thumb {
                    bottom: -14px;
                    width: 23px;
                    height: 23px;
                    right: -17px;
                }
            }
            .error-msg {
                color: red;
                font-size: 12px;
                margin-top: 5px;
                margin-bottom: 5px;
                font-weight: normal;
            }
        }
    }    

    // Multiple benefit dialog scroll on mobile devices
    @include media-breakpoint('lt-md') {
        .multiple-benefit-dialog {
            .benefit-dialog-box {
                height: calc(72vh - 70px);
                overflow-y: scroll;
            }
        }
        .expenses-dialog {
            .dialog-box {
                height: calc(66vh - 70px);
                overflow-y: scroll;
            }
        }        
    }
    
    // expenses dialog css
    .expenses-dialog {
        padding: 10px;
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        .dialog-box {
            max-width: 455px;
            box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
            margin: auto;
            position: relative;
            top: 12%;
            padding: 30px 24px 22px 24px; 
            border-radius: 5px;     
            font-weight: 600;
            overflow: auto;
            .hire-btn {
                cursor: pointer;
                border: none;
                background: none;
                color: map-get($accent, 600);
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;                
            }
            .mat-checkbox-label {
                font-size: 14px;
            }
            .dialog-button {
                float: right;           
            }
            .dialog-close {
                position: absolute;
                top: 5px;
                right: 15px;
                font-size: 18px;
                cursor: pointer;
            }             
            .expenses-input {                
                border: 2px solid map-get($accent, 500);
                width: 60px;
                padding: 4px 0px 4px 4px;
                border-radius: 5px;
                font-weight: 600;
                text-align: center;
                margin-right: 7px;            
            }
            .dialog-slider {
                width: 100%;
                padding: 0px;
                height: 25px;
                .mat-slider-wrapper {
                    left: 0px;
                    right: 0px;
                    top: 10px;
                }
                .mat-slider-track-wrapper {
                    height: 8px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;                
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;                   
                }
                .mat-slider-track-fill {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;                
                }
                .mat-slider-track-background {
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;     
                    left: 4px;                           
                }
                .mat-slider-thumb {
                    bottom: -14px;
                    width: 23px;
                    height: 23px;
                    right: -17px;
                }
            }
            .error-msg {
                color: red;
                font-size: 12px;
                margin-top: 5px;
                margin-bottom: 5px;
                font-weight: normal;
            }
        }
    }    

}

.highcharts-data-label {
    opacity: 1 !important;
}

span > .pay-node-wrapper {
    top: 0px !important;
    position: relative;
    z-index: 0;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

span > .pay-node-wrapper > .pay-node-detail {
    padding-left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-overflow: ellipsis;
    overflow: hidden;
    .pay-node-text {
        font-size: 12px; 
    }    
    .pay-node-amount {
        font-size: 14px;
    }    
}

span > .pay-node-wrapper > .pay-border-left {
    width: 2px;
    position: absolute;
    left: 0px;    
    top: 0px;
}

span > .pay-node-wrapper > .pay-icon-wrapper {
    width: 30px;
    position: absolute;
    right: 0px;    
    top: 0px;
    text-align: center;    
    .pay-node-icon {
        font-size: 14px;
        color: white;
    }
}

.ctm-cursor-pointer {
    cursor: pointer;
}

.b-d-visible {
    display: block; 
}

.b-d-unvisible {
   display: none; 
}

@include mat.all-component-typographies($typography);

.ctm-tooltip {
    background: #ffffff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 10px !important;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.align-span {
    color: #87AA88;
    span {
        float: left;
    }
}