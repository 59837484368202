@mixin md-list-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-md-list-card {
        .benio-md-list-card {
            background: white;
        
            .icon-bg {
                background-color: map-get($primary,50);
                .icon {
                    color:map-get($primary,500);
                }
            }
            
            .title {
                color: black;
            }
            .name {
                color: grey;
            }
            .number{
                color: black;
            }
        }
    }

}
