@mixin image-select-dialog-theme($theme) {

  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);  

  .image-select-dialog {

    @include media-breakpoint('xs') {
      width: 100%;
    }

    @include media-breakpoint('gt-xs') {
      width: 1200px;
    }

    .mat-dialog-container {
      padding: 0 !important;
    }

    .dialog-content-wrapper {
      max-height: 85vh;
      display: flex;
      flex-direction: column;
    }

    .primary-color-input,
    .secondary-color-input {
      padding: 6px 8px;
    }

    .img-select-border {
      border: 4px solid map-get($primary, 500);
    }

  }

}  