@mixin my-polls-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #my-polls {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }
        }
        
        .pending-section {
            background: #FAFAFA;
            .subtitle{
                color: map-get($primary,500);
            }
        }
        .answered-section {
            background: #F5F5F5;
        }

    }


}
