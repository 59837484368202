@mixin my-feedback-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #my-feedback {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                    color: white;
                }
        }
        .summary-section {
            background: #fafafa;

            .subtitle {
                color: map-get($primary,500);
            }
        }
    
        .received-section{
            background: #F5F5F5;
        }

    }


}
