@mixin contract-dialog-theme($theme) {

  .contract-dialog {

    @include media-breakpoint('xs') {
      width: 100%;
    }

    @include media-breakpoint('gt-xs') {
      width: 540px;
    }

    .mat-dialog-container {
      padding: 0 !important;
    }

    .contract-dialog-content {
      max-height: 85vh;
      display: flex;
      flex-direction: column;
    }

    .primary-color-input,
    .secondary-color-input {
      padding: 6px 8px;
    }
  }

}  