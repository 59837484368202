@mixin admin-polls-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #admin-polls {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                    color: white;
                }
        }

        .tabs {            
            .mat-tab-label-container {
                width: 100%;
                max-width: 1280px;
                margin: 0 auto;
                padding: 0px 32px;
            }

            .mat-tab-header {
                background-color: map-get($primary, 500) !important;
                border: none;
            }   
            
            // tab labels
            .mat-tab-labels, .mat-tab-label, .mat-tab-link {
                background-color: map-get($primary,500);
                color:white ;
            }


            // active tab label
            .mat-tab-label-active {
                background-color: map-get($primary,500);
                color: white;
                opacity: 1;
            }

            // ink bar
            .mat-ink-bar {
                background-color: white;
            }
        }

    }


}
