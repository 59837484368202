@mixin users-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);


    #users {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }
            .search-wrapper {
                background: map-get($background, card);

                .search {

                    .mat-icon {
                        color: map-get($foreground, icon);
                    }

                    input {
                        background: map-get($background, card);
                        color: map-get($foreground, text);
                    }
                }
            }
        }

        .content {

            .sidebar {

                .sidebar-content {
                    .header {
                        background: map-get($background, card);
                    }

                    .card {
                        background: map-get($background, card);
                        .filter-icon {
                            color:map-get($primary,500);
                        }
                        
                    }
                }
            }
        }
    }
}