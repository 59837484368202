@mixin my-benefits-theme($theme) {
    
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #my-benefits {

        .header,.report{
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }
        }

        .info,.family,.market-section{
            background-color: #FAFAFA;
        }

        .health,.retirement,.others-company  {
            background-color: #F5F5F5;
        }

        .others {
            background: url('/assets/images/backgrounds/benefits-edited.jpg') no-repeat;
            background-size: cover;
            background-position: center;
        }

        .ctm-info-button {
            background-color: #ffffff;
        }

        // Star rating style
		.rating {
			justify-content: left !important;
			.star-container {
				margin-left: 0px !important;
			}
        }        

        .average-rate {
            .rating {
              float: left;
              .star-container {
                margin-right: 10px;
              }				
            }
        }      
        
        .total-ratings {
            margin-top: 8px;
            font-size: 13px;    
        }         

    }
}