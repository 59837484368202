@mixin page-header-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-page-header {
        .benio-page-header {
            background: #019ae8;
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .breadcrumb {
                color: rgba(255, 255, 255, 0.70);
            }
        
            .header {
                color: white;
            }

            .sub-header {
                color:  white;
            }
            
        }
    }

}
