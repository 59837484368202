@mixin sm-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-sm-card {
        .benio-sm-card {
            background: white;

            .number {
                color: map-get($primary,500);
            }
        
            .info2 {
                color: map-get($accent,500);
            }
            
            .edit {
                background-color:#A9A9A9;

                .edit-icon {
                    color: white;
                }
                
            }
            
        }
    }

}
