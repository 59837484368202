@mixin admin-suggestions-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #admin-suggestions {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                    color: white;
                }
        }

        .suggestion-list-section {
            background: #FAFAFA;
            .poll-icon {
                background-color: map-get($primary,50);
                color:map-get($primary,500);
            }
        }
        .suggestion-charts-section {
            background: #f5f5f5;
            .view-card{
                background: white;
            }

            .answered-evo{
                color: black;
            }
            .answered-percentage{
                color: map-get($accent,500);
            }
            .answered-total{
                color: map-get($primary,500);
            }
        }

    }


}
