@mixin md-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-md-card {
        .benio-md-card {
            background: white;

            .title-icon {
                color: map-get($primary, 500);
            }

            .title {
                //color:#A9A9A9;
            }
                    
        }
    }

}
