@mixin admin-satisfaction-theme($theme) {
    
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #admin-satisfaction {

        .header,.next-polls{
            background: map-get($primary,400); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                color: white;
            }

            .white-text {
                color: white;
            }
        }

        .evolution {
            background-color: #FAFAFA;
            .view-card{
                background: white;
            }
        }

        .last-poll {
            background-color: #F5F5F5;
            .satisfaction-index {
                color:#C27272;
            }
            .satisfaction-evo,.detractor-text,.pasive-text,.promotor-text,.sent-text, .answer-ratio {
                color: #6e6e6e;
            }
        }


    }
}