@mixin md-full-card-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    benio-md-full-card {
        .benio-md-full-card{
            .content {
                background: white;
                .card-header{
                    background: white;

                } 
                    
                .icon-bg {
                    background-color: map-get($primary,50);
                    .title-icon {
                        color:map-get($primary,500);
                    }
                }
    
                .card-subtitle, .card-subheading { 
                    color:#777;
                }
            }
                       
        }
    }

}
