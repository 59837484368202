@mixin admin-home-theme($theme) {

    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    #admin-home {

        .header {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

            .title,.logo {
                    color: white;
                }
        }

        .links {
            background: #FAFAFA ;      
        }

        .satisfaction {
            background:#F5F5F5;
            .month-select {
              color: map-get($primary,400);  
            }
            .satisfaction-index {
                color:#C27272;
            }
            .satisfaction-evo,.detractor-text,.pasive-text,.promotor-text {
                color: #6e6e6e;
            }
        }

        .polls {
            background: map-get($primary,500); //if the next style fails
            background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);
            .polls-content {
                color: white;
                .view-card{
                    background: white;
                }
                .second-button{
                    color:black;
                    background-color: #DCDCDC;
                }
            }
            .answered-evo{
                color: black;
            }
            .answered-percentage{
                color: map-get($accent,500);
            }
            .answered-total{
                color: map-get($primary,500);
            }
        }

        .education,.suggestions {
            background:#FAFAFA;
            .view-card{
                background: white;
            }
        }

        .feedback {
            background:#F5F5F5;
            .view-card{
                background: white;
            }
        }
        .feedback-total{
            color: map-get($primary,500);
        }
        .feedback-evo{
            color: black;
        }

    }


}
