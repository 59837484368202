@mixin login-theme($theme) {

    $foreground: map-get($theme, foreground);

    login {

        #login {

            #login-form-wrapper {

                #login-form {
                    background: white;

                    .separator {
                        color: map-get($foreground, divider);

                        .text {

                            &:before,
                            &:after {
                                border-top-color: map-get($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
}