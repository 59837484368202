@mixin send-enps-report-theme($theme) {

  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);

  #send-enps-report {

    .header, .next-polls {
      background: map-get($primary,400);
      background: linear-gradient(to right, map-get($primary,400) 0%, map-get($accent,500) 100%);

      .title,.logo {
        color: white;
      }

      .white-text {
        color: white;
      }
    }

    .evolution {
      background-color: #FAFAFA;
      .view-card{
        background: white;
      }
    }
    
    .last-poll {
      background-color: #F5F5F5;
      .satisfaction-index {
        color:#C27272;
      }
      .satisfaction-evo,.detractor-text,.pasive-text,.promotor-text,.sent-text, .answer-ratio {
        color: #6e6e6e;
      }
      .detractor-num {
        color: #E99393;
      }
      .pasive-num {
        color: #BEAD8A;
      }
      .promotor-num {
        color: #A3BE8A;
      }
    }    
    
  }

}  